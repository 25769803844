:root {
  --size-3: 0.75rem;
  --size-5: 1.25rem;
  --size-7: 1.75rem;
  --size-10: 2.5rem;
  --font-size-3: 1rem;
  --font-size-4: 1.25rem;
  --font-size-6: 2rem;
  --font-size-7: 2.5rem;
  --font-weight-4: 400;
  --radius-3: 0.75rem;
  --ratio-square: 1;
  --size-content-1: 20ch;
  --text-color: #e3e3e3;
  --subheading-color: #828282;
  --placeholder-color: #a6a6a6;
  --primary-color: #242424;
  --secondary-color: #383838;
  --secondary-hover-color: #444;
}

.light_mode {
  --text-color: #222;
  --subheading-color: #a0a0a0;
  --placeholder-color: #6c6c6c;
  --primary-color: #fff;
  --secondary-color: #e9eef6;
  --secondary-hover-color: #dbe1ea;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  timeline-scope: --content-1, --content-2, --content-3, --content-4;
}
